import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ModalHeader } from 'shared/ModalHeader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const FileSharingDetailsModal = ({ show, onHide }) => {
  const [selectedLog, setSelectedLog] = useState(null);

  const generatedLinks = Array.from({ length: 20 }).map((_, index) => ({
    lawft_link_nickname: 'Sent to DA',
    status: index % 2 === 0 ? 'Expires in 45 mins' : `Expired on 11/12/24, 12:00PM`,
    views: Math.floor(Math.random() * 100),
    downloads: Math.floor(Math.random() * 50),
    statusClass: index % 2 === 0 ? 'text-expire' : '',
  }));

  const fileLogs = Array.from({ length: 5 }).map((_, index) => ({
    timestamp: `11/12/24, ${index + 1}:00PM`,
    views: Math.floor(Math.random() * 100),
    downloads: Math.floor(Math.random() * 50),
  }));

  const handleEyeClick = (logIndex) => {
    setSelectedLog(logIndex); // Set the selected log index
  };

  const actionBody = (rowData) => {
    return <Button icon="pi pi-eye" className="p-button-text" onClick={() => handleEyeClick(rowData.index)} />;
  };

  const paginatorTemplate = {
    first: 'First',
    prev: 'Previous',
    next: 'Next',
    last: 'Last',
    rowsPerPage: 'Rows per page',
  };

  return (
    <>
      <Dialog
        header={ModalHeader}
        closable={false}
        visible={show}
        modal
        style={{ width: '80vw' }}
        onHide={() => {
          setSelectedLog(null); // Reset selected log on close
          onHide();
        }}
        resizable={false}
        className="create-msg-popup"
      >
        <div className="py-4">
          <div className="modal-heading F-size16 text-bold mb-2">Harrison Asset Discovery.pdf</div>
          <div className="d-flex">
            <p>
              Total Views <span className="text-bold F-size16 ms-2">55</span>
            </p>
            <p className="ms-4">
              Total Downloads <span className="text-bold F-size16 ms-2">30</span>
            </p>
          </div>

          <div className="d-flex align-items-start" style={{ gap: '1rem' }}>
            {/* Generated Links Section using DataTable */}

            <div
              className="flex-grow-1 file-sharedlist-table"
              style={{
                maxHeight: '300px',
                overflowY: 'auto',
                width: selectedLog !== null ? '60%' : '100%', // Adjust width when eye button is clicked
                transition: 'width 0.3s ease', // Smooth transition for resizing
              }}
            >
              <h5 class="caption-bold mb-2">Generated Links</h5>

              <div className="col-12 p-0 datatable-responsive">
                <DataTable value={generatedLinks} className="p-datatable-responsive">
                  <Column
                    header="Lawft Link Nickname"
                    body={(rowData, { rowIndex }) => (
                      // <a href={rowData.url} target="_blank" rel="noopener noreferrer">
                      //   {rowData.url}
                      // </a>
                      <>{`${rowData.lawft_link_nickname} (${rowIndex + 1})`}</>
                    )}
                  />
                  <Column
                    header="Status"
                    body={(rowData, { rowIndex }) => (
                      <span className={rowData.statusClass}>
                        {rowIndex % 2 === 0 ? (
                          'Expires in 45 mins'
                        ) : (
                          <>
                            <span className="F-size12 black-500">Expired on</span>
                            <br />
                            11/12/24, 12:00PM
                          </>
                        )}
                      </span>
                    )}
                  />
                  <Column
                    header="Activity"
                    body={(rowData) => (
                      <>
                        <div>
                          <span className="F-size12 black-500 me-2">Views</span> {rowData.views}
                        </div>{' '}
                        <div>
                          <span className="F-size12 black-500 me-2">Downloads</span> {rowData.downloads}
                        </div>
                      </>
                    )}
                  />
                  <Column header="Action" body={actionBody} />
                </DataTable>
              </div>
            </div>

            {/* File Activity Logs Section (Conditional) */}
            {selectedLog !== null && (
              <div
                className="p-4 file-sharedlist-table"
                style={{
                  width: '35%', // Take up more space when eye button is clicked
                  maxHeight: '300px',
                  overflowY: 'auto',
                  transition: 'width 0.3s ease', // Smooth transition for resizing
                }}
              >
                <h5 class="caption-bold mb-2">File Activity Logs</h5>

                <DataTable value={fileLogs} paginator paginatorTemplate={paginatorTemplate} rows={5}>
                  {/* <Column header="Timestamp" body={(rowData) => rowData.timestamp} /> */}
                  <Column header="Views (10)" body={(rowData) => rowData.timestamp} />
                  <Column header="Downloads (12)" body={(rowData) => rowData.timestamp} />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
