// import useDeletePermission from "modules/file_manager/hooks/useDeletePermission";
import React, { useState } from 'react';
import FileDownload from './FileDownload';
import PrintFile from './PrintFile';
import MenuIcon from '../../../GridView/MenuIcon';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Tooltip } from 'primereact/tooltip';
import { useHistory } from 'react-router-dom';
import { Buffer } from 'buffer';
import Cookies from 'universal-cookie';
import { FileSharingClientDetailsModal } from 'components/FileSharing/FileSharingClientDetailsModal';
const cookie = new Cookies();

function ActionsTemplate({ rowData, e, itemsRef }) {
  const { isClientFileManager, isLeadFileManager } = useFileManagerContext();

  const history = useHistory();
  const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const printFileFormats = ['image/png', 'application/pdf', docTypeVal];

  const [clientDetailsModalshow, setClientDetailsModalshow] = useState(false);

  const redirectFileDetails = (rowData) => {
    let data = {};
    let currentUser = cookie.get('userId') || null;
    data.file_pk = rowData.pk;
    data.file_sk = rowData.sk;
    data.user_id = currentUser;
    // const myObj = JSON.stringify(data);
    // let buff = Buffer.from(myObj);
    // let stringToBase64 = buff.toString('base64');
    // history.push(`/sign-document?esignature-token=${stringToBase64}`);

    let obj = {
      case_id: rowData.case_id,
      file_pk: rowData?.pk,
      file_sk: rowData?.sk,
      data: {},
      is_lead: isLeadFileManager,
    };
    history.push({
      pathname: '/sign-document',
      state: obj,
    });
  };

  return (
    <React.Fragment key={e.rowIndex}>
      <FileSharingClientDetailsModal
        show={clientDetailsModalshow}
        setShow={setClientDetailsModalshow}
        onHide={() => {
          setClientDetailsModalshow(false);
        }}
        rowData={rowData}
      />
      <span className="p-column-title text-break">Actions</span>
      <span className="text-break relative d-flex align-items-center">
        {rowData.entry_type !== 'folder' && (
          <>
            {/* <span className="position-relative">
              <i
                id={`copy-icon-tooltip-${rowData.sk}`}
                class="fas fa-link me-3 text-primary-main pointer copy-icon"
                onClick={() => {
                  setClientDetailsModalshow(true);
                }}
              ></i>
              <Tooltip target={`#copy-icon-tooltip-${rowData.sk.replace(/[^\w\-]/g, '\\$&')}`} position="top">
                Copy
              </Tooltip>
            </span> */}
            {isClientFileManager && rowData && rowData.esignature_status && rowData.esignature_status === 'PENDING' && (
              <span key={e.rowIndex} className="table-icons">
                <i
                  onClick={() => redirectFileDetails(rowData)}
                  className="pi pi-pencil me-2 custom-tooltip-signature"
                  aria-hidden="true"
                ></i>
                <Tooltip target={`.custom-tooltip-signature`} position="top">
                  Signature
                </Tooltip>
              </span>
            )}
            {rowData?.file_type && printFileFormats?.includes(rowData?.file_type?.toLowerCase()) ? <PrintFile rowData={rowData} /> : null}

            <FileDownload rowData={rowData} />
          </>
        )}

        {!isClientFileManager ? <MenuIcon item={rowData} index={e.rowIndex} itemsRef={itemsRef} /> : null}
      </span>
    </React.Fragment>
  );
}

export default ActionsTemplate;
