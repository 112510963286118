import React, { useState } from 'react';
import { Card } from 'primereact/card';

const FileSharingDetailsCard = () => {
  return (
    <div className="file-sharing-details">
      <div className="shared-card-container">
        <Card className="shared-card">
          {/* Shared with Client Section */}
          <div className="shared-with-client">
            <i className="fas fa-check-circle shared-icon"></i>
            <span className="shared-text">Shared with client</span>
          </div>

          {/* Grey Background Section */}
          <div className="grey-section pointer">
            {/* Title Section */}
            <div className="text-bold black-600 mb-2">Lawft Link</div>

            {/* Downloads and Views Section */}
            <div className="downloads-views-section">
              <div className="info">
                <div className="p-column-title">Downloads</div>
                <div className="value">55</div>
              </div>
              <div className="info">
                <div className="p-column-title">Views</div>
                <div className="value">30</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FileSharingDetailsCard;
